import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Steps, Timeline } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { pickBy } from 'lodash';

import SelectResources from '../SelectResources';
import Billing from '../Billing';
import Summary from '../Summary';

import {
  setSelectedResources,
  setResourceData,
  setResourceDataLoaded,
  setPerUnitData,
} from '../SelectResources/reducer';
import './style.scss';
import Invoice from '../Invoice/Invoice';
import { extractDataFromQuoteOfflineURL, getSummarizedCost, getSummaryData } from '../Summary/utils';
import { useLocation } from 'react-router-dom';
import { setStep } from './reducer';
import { setDestination, setEmail, setName, setCompanyName, setBillingAddress, setSource, setService } from '../Billing/reducer';
import {setInvoiceDue, setInvoiceIssued, setSupport} from '../Summary/reducer';

const { Step } = Steps;

const Workflow = ({ offlineInvoice, type }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { step, order, status, showRedirect } = useSelector(state => state.workflow);
  const { selectedResources, perUnitData, resourceData, isResourceDataFetched } = useSelector(state => state.resources);
  const { source, name, service } = useSelector(state => state.billing);
  const [costSummaryDetails, setCostSummaryDetails] = useState({});
  const [offlineInvoiceResourceCost, setOfflineInvoiceResourceCost] = useState(0);
  const [offlineInvoiceProgramManagement, setOfflineInvoiceProgramManagement] = useState(0);
  const [offlineInvoiceTotalCost, setOfflineInvoiceTotalCost] = useState(0);
  const [paymentOption, setPaymentOption] = useState('yearly');

  const invoiceRef = useRef(null);

  let offlineInvoiceData = null;
  if (offlineInvoice) {
    const offlineInvoiceUrlId = location.pathname?.split('/quote/')[1];
    offlineInvoiceData = extractDataFromQuoteOfflineURL(offlineInvoiceUrlId);
    if (!offlineInvoiceData) {
      return (
        <div>Quote link expired or invalid</div>
      );
    }
  }

  // Initialize resourceData with empty object for each type and Remove cloud entries that were unselected
  useEffect(() => {
    dispatch(setSelectedResources(pickBy({
      ...source.reduce((acc, type) => {
        if (!acc[type]) {
          acc[type] = {};
        }
        return acc;
      }, { ...selectedResources }),
    }, (_, key) => source.includes(key))));
  }, [source]);


  useEffect(() => {
    (async () => {
      dispatch(setResourceData(await (await fetch(service === 'maas' ? `${process.env.REACT_APP_BILLING_API}/v1/prices?service=manage-service` : `${process.env.REACT_APP_BILLING_API}/v1/prices`)).json()));
      setTimeout(() => dispatch(setResourceDataLoaded()), 250);
    })();
  }, [service]);

  useEffect(() => {
    if (isResourceDataFetched && offlineInvoice && offlineInvoiceData) {
      const {
        name,
        email,
        companyName,
        billingAddress,
        source,
        destination,
        support,
        resource_list,
        invoiceIssued,
        invoiceDue,
        resourceCost,
        totalCost,
        projectManagment,
        supportCost,
        service,
      } = JSON.parse(offlineInvoiceData);
      dispatch(setStep(2));
      dispatch(setService(service));
      dispatch(setName(name));
      dispatch(setEmail(email));
      dispatch(setCompanyName(companyName));
      dispatch(setBillingAddress(billingAddress));
      dispatch(setSource(source));
      dispatch(setDestination(destination));
      dispatch(setSupport(support));
      dispatch(setPerUnitData(resource_list));
      dispatch(setInvoiceIssued(invoiceIssued));
      dispatch(setInvoiceDue(invoiceDue));

      setCostSummaryDetails(state => ({
        ...state,
        totalcost: totalCost,
        resourceCost,
        projectManagment,
        supportCost,
      }));
      setOfflineInvoiceResourceCost(resourceCost);
      setOfflineInvoiceProgramManagement(projectManagment);
      setOfflineInvoiceTotalCost(totalCost);
    }
  }, [isResourceDataFetched, offlineInvoice]);

  const stepDisplay = () => {
    if (step === 0) {
      return <Billing type={type} />;

    } else if (step > 0 && step < 2) {
      return <SelectResources />;
    } else if (step >= 2 && step < 4) {
      return (
        <>
          <div className='grey-border'>
            <div ref={invoiceRef}>
              <Invoice
                offlineInvoice={offlineInvoice}
                step={step}
                order={order}
                service={service}
                invoice={getSummaryData(perUnitData, resourceData)}
                cost={getSummarizedCost({
                  service,
                  paymentOption,
                  ...costSummaryDetails,
                })}
              />
            </div>
          </div>
        </>
      );
    } else {
      return <div className='mp-success'>
        <span className='details-title'>
          Order Status
        </span>
        <p className='details-subtitle'>
          Thank you for your order {name}, we are now processing your request.<br />
        </p>
        <Timeline pending={(() => {
          if (status === 1) {
            return 'Submitting Request';

          } else if (status === 2 && order !== 'error') {
            return <>
              Generating Invoice<br />
              You will receive an email for your invoice once your order has finished processing.<br />
              Feel free to leave this page or wait to be redirected when your invoice is ready.
            </>;
          }
        })()}>
          {order === 'error' && <Timeline.Item color="red" dot={<CloseCircleOutlined />}>Error submitting request</Timeline.Item>}
          {status > 1 && order !== 'error' && <Timeline.Item color="green" dot={<CheckCircleOutlined />}>Request Submitted</Timeline.Item>}
          {status > 2 && <Timeline.Item color="green" dot={<CheckCircleOutlined />}>Invoice Generated</Timeline.Item>}
        </Timeline>
        {
          order === 'error' && <>
            An error occured while submitting your request. Please click here to try again.
          </>
        }
        {status > 2 && <>
          Your invoice is ready, you will be redirected shortly...<br />
          {showRedirect && <>Click <a href={order.invoice}>here</a> if you are not redirected.</>}
        </>}
      </div>;
    }
  };

  return (
    <>
      <div className={`mp-workflow ${step === 4 ? 'mp-workflow-success' : ''}`}>
        <Steps
          size="small"
          className="mp-progress"
          current={step}
        >
          <Step
            title="Account Details"
          />
          <Step
            title="Select Resources"
          />
          <Step
            title="Sign Quote"
          />
          <Step
            title="Review &amp; Place Order"
          />
        </Steps>
        <div className='mp-step'>{stepDisplay()}</div>
      </div>
      <Summary
        ref={invoiceRef}
        paymentOption={paymentOption}
        setPaymentOption={setPaymentOption}
        updateCostSummaryDetails={(updatedDetails) => setCostSummaryDetails(state => ({ ...state, ...updatedDetails }))}
        offlineInvoice={offlineInvoice}
        offlineInvoiceCostData={{
          offlineInvoiceResourceCost,
          offlineInvoiceProgramManagement,
          offlineInvoiceTotalCost,
        }}
      />
    </>
  );
};

export default Workflow;
