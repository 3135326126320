import { Table } from 'antd';

const TableHeader = ({ text }) => {
  return <Table
    tableLayout="fixed"
    pagination={false}
    rowSelection={{
      type: 'checkbox',
      hideSelectAll: true,
    }}
    expandable={{
      expandedRowRender: true,
    }}
    columns={[{
      title: () => <span style={{ fontWeight: 700 }}>{text}</span>,
      dataIndex: null,
    }]}
  />;
};

export default TableHeader;
