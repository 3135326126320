function getCloudItemEntry(resourceData, _cloud, _category, _row) {
  const cloud = resourceData.find(c => c.id === _cloud);
  if (arguments.length === 1) return { cloud };

  const category = [
    ...cloud.categories,
    ...(cloud?.tabs ?? []).map(tab => tab.categories).flat(),
  ].find(c => c.id === _category);
  if (arguments.length === 2) return { cloud, category };

  const row = category.rows.find(c => c.id === _row);
  return { cloud, category, row };
}

export {
  getCloudItemEntry,
};
