import { useEffect } from 'react';
import Workflow from './components/Workflow';
import './style.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { services } from './components/Billing/utils';

const App = () => {
  const currertLocation  = window.location.pathname;
  let type = currertLocation?.split('/')[1];

  useEffect(() => {
    const selectedType = services?.find((service) => (service?.id === type));
    if (!selectedType?.id) {
      window.history.pushState(null, '', '/');
    } else {
      type = selectedType?.id;
    }

  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <div className='mp-container'>
              <Workflow />
            </div>
          }
        />
        <Route
          path='/:type'
          element={
            <div className='mp-container'>
              <Workflow type={type} />
            </div>
          }
        />
        <Route
          path='/quote/*'
          element={
            <div className='mp-container'>
              <Workflow offlineInvoice />
            </div>
          }
        />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
