// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectResources {
  transition: opacity 0.5s ease-in-out;
}
.selectResources .ant-table-placeholder {
  display: none;
}
.selectResources .selectResourcesTableContainer .cds--tabs__nav-link {
  max-width: initial;
}
.selectResources .selectResourcesTableContainer .cds--tab-content {
  padding: initial !important;
}
.selectResources .selectResourcesTableContainer .cds--tab--list {
  gap: 0px;
}
.selectResources .selectResourcesTableContainer td.cds--table-expand + .cds--table-column-checkbox + td {
  padding-left: 1rem;
}
.selectResources .selectResourcesTableContainer .resourceTab {
  margin-left: initial;
}

.testing {
  transition: 0.5s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}`, "",{"version":3,"sources":["webpack://./src/components/SelectResources/style.scss"],"names":[],"mappings":"AAAA;EAIE,oCAAA;AACF;AACE;EACE,aAAA;AACJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,2BAAA;AAFN;AAKI;EACE,QAAA;AAHN;AAMI;EACE,kBAAA;AAJN;AAOI;EACE,oBAAA;AALN;;AAUA;EAIE,4BAAA;EAIA,yBAAA;EAEA,iBAAA;EAEA,oBAAA;EACA,YAAA;EAEA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,iBAAA;AATF","sourcesContent":[".selectResources {\n  -webkit-transition: opacity 0.5s ease-in-out;\n  -moz-transition: opacity 0.5s ease-in-out;\n  -o-transition: opacity 0.5s ease-in-out;\n  transition: opacity 0.5s ease-in-out;\n\n  .ant-table-placeholder {\n    display: none;\n  }\n\n  .selectResourcesTableContainer {\n    .cds--tabs__nav-link {\n      max-width: initial;\n    }\n\n    .cds--tab-content {\n      padding: initial !important;\n    }\n\n    .cds--tab--list {\n      gap: 0px;\n    }\n\n    td.cds--table-expand+.cds--table-column-checkbox+td {\n      padding-left: 1rem;\n    }\n\n    .resourceTab {\n      margin-left: initial;\n    }\n  }\n}\n\n.testing {\n  -webkit-transition: 0.5s ease-in-out;\n  -moz-transition: 0.5s ease-in-out;\n  -o-transition: 0.5s ease-in-out;\n  transition: 0.5s ease-in-out;\n\n  -moz-user-select: -moz-none;\n  -khtml-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n\n  pointer-events: none;\n  opacity: 0.5;\n  -webkit-filter: blur(5px);\n  -moz-filter: blur(5px);\n  -o-filter: blur(5px);\n  -ms-filter: blur(5px);\n  filter: blur(5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
