import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import SelectResourcesTable from './SelectResourcesTable';

const SelectResourcesTableContainer = () => {
  const { config, resourceData } = useSelector(state => state.resources);
  const tableData = resourceData.find((data) => data.id === config);
  if (!tableData) return null;

  const { headers, id, name, categories } = tableData;
  const tabs = [
    { id, name, categories }, // self as the 1st tab
    ...tableData.tabs ?? [],
  ];

  return (
    <div className='selectResourcesTableContainer'>
      <Tabs
        key={`tabs-${config}`}
        items={tabs.map((tab) => ({
          key: `tab-${tab.id}`,
          label: tabs.length > 1 && tab.name,
          children: <SelectResourcesTable
            key={`table-${tab.id}`}
            headers={headers}
            categories={tab.categories}
          />
        }))}
      />
    </div>
  );
};

export default SelectResourcesTableContainer;
