import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  config: null,
  selectedResources: {},
  resourceData: null,
  isResourceDataFetched: false,
  resourceDataLoaded: false,
  perUnitData: {},
};

const mainSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setSelectedResources(state, action) {
      state.selectedResources = action.payload;
    },
    setConfig(state, action) {
      state.config = action.payload;
    },
    setResourceData(state, action) {
      state.resourceData = action.payload;
      state.isResourceDataFetched = true;
    },
    setResourceDataLoaded(state) {
      state.resourceDataLoaded = true;
    },
    setPerUnitData(state, action) {
      state.perUnitData = action.payload;
    }
  },
});

export const {
  setSelectedResources, setConfig,
  setResourceData, setResourceDataLoaded,
  setPerUnitData,
} = mainSlice.actions;
export default mainSlice.reducer;
