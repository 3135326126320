import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { setPerUnitData, setSelectedResources } from '../SelectResources/reducer';
import {
  setSource,
  setDestination,
  setEmail,
  setLName,
  setCompanyName,
  setBillingAddress,
  setService,
  setrequesterEmail,
  setName,
} from './reducer';
import { setEmailError } from './reducer';
import { sourceEnvironments, destinationEnvironments, services } from './utils';
import { validEmail } from '../Summary/utils';
import './style.scss';

const { Option } = Select;

const Billing = ({ type }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    source,
    destination,
    email,
    name,
    Last,
    showEmailError,
    companyName,
    requesterEmail,
    billingAddress,
    service,
  } = useSelector(state => state.billing);

  const { resourceDataLoaded } = useSelector(state => state.resources);
  const groupedOptions = sourceEnvironments.reduce((acc, { category, id, text }) => {
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ id, text });
    return acc;
  }, {});
  const getFilteredGroupedOptions = () => groupedOptions['source'].filter(item => ['ibmCloudClassic', 'ibmCloud', 'aws'].includes(item.id));
  return (
    <>
      <div className='details-container'>
        <span className='details-title'>Account Details</span>
        <p
          className='details-subtitle'
          style={{
            marginBottom: '20px',
          }}
        >
          The following details are required for ordering
        </p>
        <div className='content'>
          <Form form={form}>
            <h2 className='title'>Environment Details</h2>
            <div className='single-input-box'>
              <Form.Item
                name='service'
                id='service'
                label="Select Service"
                required
                initialValue={service}
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch={false}
                  value={service}
                  placeholder='Choose an option'
                  onChange={(value) => {
                    dispatch(setService(value));
                    dispatch(setSelectedResources({}));
                    dispatch(setPerUnitData({}));
                  }}
                >
                  {services.map(({ id, text }) => (
                    <Option key={id} value={id}>{text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='user-details'>
              <div className='input-box'>
                <Form.Item
                  id='source'
                  label={service === 'maas' ? 'Environment' : 'Source Environment'}
                  required
                  labelCol={{span: 24}}
                >
                  <Select
                    mode='multiple'
                    allowClear
                    showSearch={false}
                    placeholder='Choose an option'
                    value={source}
                    onChange={(selectedItems) => dispatch(setSource(selectedItems))}
                    loading={!resourceDataLoaded}
                  >
                    {service === 'maas' ? getFilteredGroupedOptions()?.map(({ id, text }) => (
                      <Option key={id} value={id}>{text}</Option>
                    )) : groupedOptions['source'].map(({ id, text }) => (
                      <Option key={id} value={id}>{text}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              { service !== 'maas' && ( <div className='input-box'>
                <Form.Item
                  id='destination'
                  label="Destination Environment"
                  required
                  labelCol={{ span: 24 }}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch={false}
                    placeholder='Choose an option'
                    value={destination.map((_id) => {
                      const {id, text} = destinationEnvironments.find(i => i.id === _id);
                      return <Option key={id}>{text}</Option>;
                    })}
                    onChange={(selectedItems) => dispatch(setDestination(selectedItems))}
                    disabled={!resourceDataLoaded}
                    loading={!resourceDataLoaded}
                  >
                    {destinationEnvironments.map(({id, text}) => <Option key={id}>{text}</Option>)}
                  </Select>
                </Form.Item>
              </div> )}
            </div>
            <h2 className='title'>Contact Details</h2>
            <div className='user-details'>
              <div className='input-box'>
                <Form.Item
                  id='first-name'
                  label='First Name'
                  required
                  labelCol={{span: 24}}
                >
                  <Input
                    placeholder='John'
                    value={name}
                    onChange={({target: {value}}) => dispatch(setName(value))}
                  />
                </Form.Item>
              </div>


              <div className='input-box'>
                <Form.Item
                  id='last-name'
                  label='Last Name'
                  required
                  labelCol={{span: 24}}
                >
                  <Input
                    placeholder='Doe'
                    value={Last}
                    onChange={({target: {value}}) => dispatch(setLName(value))}
                  />
                </Form.Item>
              </div>
              <div className='input-box'>
                <Form.Item
                  id='company-email'
                  label='Your Email'
                  required
                  labelCol={{span: 24}}
                  {...(
                    showEmailError && {
                      validateStatus: 'error',
                      help: 'Email is invalid',
                    }
                  )}
                >
                  <Input
                    placeholder='billing@yourcompany.com'
                    value={email}
                    onChange={({target: {value}}) => {
                      if (showEmailError && validEmail(value)) {
                        dispatch(setEmailError(false));
                      }
                      dispatch(setEmail(value));
                    }}
                  />
                </Form.Item>
              </div>
              <div className='input-box'>
                <Form.Item
                  id='company-name'
                  label='Company Name'
                  required
                  labelCol={{span: 24}}
                >
                  <Input
                    placeholder='XYZ Company'
                    value={companyName}
                    onChange={({target: {value}}) => dispatch(setCompanyName(value))}
                  />
                </Form.Item>
              </div>


            

              <div className='input-box'>
                <Form.Item
                  id='billing-address'
                  label='User Billing Address'
                  required
                  labelCol={{span: 24}}
                >
                  <Input
                    placeholder='123, Main St, San Francisco, CA, 66801'
                    value={billingAddress}
                    onChange={({target: {value}}) => dispatch(setBillingAddress(value))}
                  />
                </Form.Item>
              </div>
              <div className='input-box'>
             
                <Form.Item
                  id='email'
                  label='Requester Email'
                  labelCol={{span: 24}}
                  {...(
                    showEmailError && {
                      validateStatus: 'error',
                      help: 'Email is invalid',
                    }
                  )}
                 
                >
                  <Input
                    placeholder='email@yourcompany.com'
                    value={requesterEmail}
                    onChange={({target: {value}}) => {
                      if (showEmailError && validEmail(value)) {
                        dispatch(setEmailError(false));
                      }
                      dispatch(setrequesterEmail(value));
                    }}
                  />
                 
                </Form.Item>
               
              </div>
             
            </div>


          </Form>
        </div>
      </div>
    </>
  );
};

export default Billing;
