import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  source: [],
  destination: [],
  email: '',
  requesterEmail:'',
  Last: '',
  name: '',
  showEmailError: false,
  companyName: '',
  billingAddress: '',
  service: '',
};

const mainSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setService(state, action) {
      state.service = action.payload;
    },
    setSource(state, action) {
      state.source = action.payload;
    },
    setDestination(state, action) {
      state.destination = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setLName(state, action) {
      state.Last = action.payload;
    },
    setEmailError(state, action) {
      state.showEmailError = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setrequesterEmail(state, action) {
      state.requesterEmail = action.payload;
    },
    setBillingAddress(state, action) {
      state.billingAddress = action.payload;
    },
   
  },
});

export const {
  setSource, setDestination, setEmail,
  setEmailError, setrequesterEmail,
  setCompanyName,setBillingAddress, setService,setName,setLName,setCompanyEmail
} = mainSlice.actions;
export default mainSlice.reducer;
