// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mp-workflow {
  min-width: 500px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 310px;
  width: 100%;
}
.mp-workflow .mp-progress {
  margin-bottom: 50px;
}
.mp-workflow .mp-step {
  margin: 0px 24px 24px 24px;
}
.mp-workflow .mp-success {
  max-width: 550px;
  margin: auto;
}
.mp-workflow.mp-workflow-success {
  margin-right: 10px;
  transition: 0.5s ease-in-out;
}

.grey-border {
  border: 2px solid #e6f7ff;
}`, "",{"version":3,"sources":["webpack://./src/components/Workflow/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AACF;AAEE;EACE,mBAAA;AAAJ;AAKE;EACE,0BAAA;AAHJ;AAOE;EACE,gBAAA;EACA,YAAA;AALJ;AAQE;EACE,kBAAA;EAKA,4BAAA;AAPJ;;AAWA;EACE,yBAAA;AARF","sourcesContent":[".mp-workflow {\n  min-width: 500px;\n  margin-top: 10px;\n  margin-left: 10px;\n  margin-right: 310px;\n  width: 100%;\n  \n\n  .mp-progress {\n    margin-bottom: 50px;\n   \n    \n  }\n\n  .mp-step {\n    margin: 0px 24px 24px 24px;\n    \n  }\n\n  .mp-success {\n    max-width: 550px;\n    margin: auto;\n  }\n\n  &.mp-workflow-success {\n    margin-right: 10px;\n\n    -webkit-transition: 0.5s ease-in-out;\n    -moz-transition: 0.5s ease-in-out;\n    -o-transition: 0.5s ease-in-out;\n    transition: 0.5s ease-in-out;\n  }\n}\n\n.grey-border {\n  border: 2px solid #e6f7ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
