import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Checkbox, Modal, Tooltip, Radio } from 'antd';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { setStep, setOrder, setStatus, setShowRedirect } from '../Workflow/reducer';
import { setEmailError } from '../Billing/reducer';

import { 
  setCollapsed,
  setAgreed,
  setSubmitting,
  setSupport,
  setSignatureImgUrl,
  resetSignatureImgUrl,
  resetInvoiceUrl,
  setInvoiceOfflineUrl,
  resetQuoteOfflineUrl,
} from './reducer';

import { getCloudItemEntry } from '../../utils';
import {
  supportValues,
  supportDisplay,
  checkIfInvoiceReady,
  validEmail,
  useProcessInvoice,
  convertSignatureToImage,
  generateQuoteOfflineURL,

} from './utils';

import React from 'react';
import './style.scss';
import ReactToPrint from 'react-to-print';
import SignatureCanvas from 'react-signature-canvas';

// eslint-disable-next-line react/display-name
const Summary = forwardRef(({ updateCostSummaryDetails, offlineInvoice, offlineInvoiceCostData, paymentOption, setPaymentOption }, ref) => {
  const containerRef = useRef(null);
  const signatureRef = useRef();
  const [isSignatureCanvasEmpty, setIsSignatureCanvasEmpty] = useState(true);
  const [currentQuoteStep, setCurrentQuoteStep] = useState(0);
  const [signQuoteBtnHoverState, setSignQuoteBtnHoverState] = useState(false);
  const [signQuoteModalVisibility, setSignQuoteModalVisibility] = useState(false);
  const dispatch = useDispatch();
  const { step } = useSelector(state => state.workflow);
  const { source, destination, email, name, companyName, billingAddress,service,requesterEmail,Last } = useSelector(state => state.billing);
  const { selectedResources, resourceData, perUnitData } = useSelector(state => state.resources);

  const { 
    collapsed,
    agreed,
    submitting,
    support,
    isInvoiceUrlGenerated,
    isSignatureImgUrlGenerated,
    isInvoiceOfflineUrlGenerated,
    invoiceIssued,
    invoiceDue,
  } = useSelector(state => state.summary);

  const [emailSent, setEmailSent] = useState(false);


  useEffect(() => {
    if (isInvoiceUrlGenerated && step < 2) {
      dispatch(resetSignatureImgUrl());
      dispatch(resetInvoiceUrl());
    }

    if (isInvoiceOfflineUrlGenerated && step < 2) {
      dispatch(resetQuoteOfflineUrl());
    }


    if (currentQuoteStep === 0 && isInvoiceUrlGenerated && isSignatureImgUrlGenerated) {
      setCurrentQuoteStep(1);
    }

    // TODO: We can do alot of improvements and optimizations when have time
    if (step === 2 && !isInvoiceOfflineUrlGenerated) {
      const data = JSON.stringify({
        service,
        name,
        email,
        companyName,
        billingAddress,
        destination,
        source,
        support,
        resource_list: perUnitData,
        invoiceIssued,
        invoiceDue,
        resourceCost: (service === 'maas') ? (resourceCost * 12) : resourceCost,
        totalCost: (service === 'maas') ? (totalCost * 12) : totalCost,
        projectManagment: (service !== 'maas') && totalcost - resourceCost,
        supportCost: (service !== 'maas') && supportCost,
      });
      const updateQuoteOfflineURL = (url) => dispatch(setInvoiceOfflineUrl(url));
      generateQuoteOfflineURL(data, updateQuoteOfflineURL);
    }


    return () => {
      dispatch(resetSignatureImgUrl());
      dispatch(resetInvoiceUrl());
      setCurrentQuoteStep(0);
      clearSignature();
    };

  }, [step, currentQuoteStep, isInvoiceOfflineUrlGenerated]);


  const clearSignature = () => {
    signatureRef.current?.clear();
    dispatch(resetSignatureImgUrl());
    setIsSignatureCanvasEmpty(true);
  };
  const acceptSignature = () => {
    const updateSignatureImgUrl = (url) => dispatch(setSignatureImgUrl(url));
    convertSignatureToImage(signatureRef.current?.getTrimmedCanvas(), updateSignatureImgUrl);
    setSignQuoteModalVisibility(false);
    dispatch(setStep(step + 1));
  };

  const onSignQuoteBtnMouseEnter = () => setSignQuoteBtnHoverState(true);
  const onSignQuoteBtnMouseLeave = () => setSignQuoteBtnHoverState(false);

  const submitOrder = async () => {
    dispatch(setOrder('processing'));
    try {
      // Submit
      dispatch(setStatus(1));

      // Wait 1 second before showing request was submitted
      setTimeout(() => dispatch(setStatus(2)), 1000);
      const result = await (await fetch(`${process.env.REACT_APP_BILLING_API}/v1/order`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          service,
          destination,
          source,
          support,
          paymentOption,
          resource_list: perUnitData,
        }),
      })).json();

      // Wait till invoice 200s
      await checkIfInvoiceReady(result.invoice);

      // Say ready and redirect user in 3 seconds
      dispatch(setStatus(3));
      dispatch(setOrder(result));
      setTimeout(() => {
        window.location.href = result.invoice;
        setTimeout(() => dispatch(setShowRedirect(true)), 3000);
      }, 3000);
    } catch (error) {
      dispatch(setOrder('error'));
    }
  };

  const sendEmail = async () => {
    

    const res = await fetch('/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        source,
        destination,
        email,
        name,
        Last,
        companyName,
        requesterEmail,
        billingAddress,
        service,
      })
    });

    const data = await res.json();
   

    if (data.status === 401 || !data) {
      console.log('error');
    } else {
      console.log('Email sent');
    }
  };


  const unitConversion = (value) => {
    const unit = 500;
    return Math.ceil(value / unit) * unit;
  };

  const {
    offlineInvoiceResourceCost,
    offlineInvoiceProgramManagement,
    offlineInvoiceTotalCost,
  } = offlineInvoiceCostData;

  let totalUnits = 0;
  let totalCost = 0;
  let resourceUnits = 0;
  let resourceCost = offlineInvoiceResourceCost || 0;
  let programManagment = offlineInvoiceProgramManagement || 0;
  let totalcost = offlineInvoiceTotalCost || 0;
  let TotalWancloudsUnit=0;
  let  finalProgramManagment =0;
  let finalResourceCost =0;
  let totalCostSubSupport=0;

  let noneSelected = true;

  const supportCost = supportValues.find(i => i.id === support?.id)?.value ?? 0;
  const inSignQuote = step === 2;
  const inReview = step === 3;
  const success = step === 4;
  const missingData = service !== 'maas' ? !(source.length > 0 && destination.length > 0 && name && Last && email && companyName && billingAddress) : !(name && Last && email && companyName && billingAddress);
  const onPaymentOptionChange = e => {
    setPaymentOption(e.target.value);
  };

  useEffect(() => {
    Object.entries(selectedResources).map(([_cloud, value], index) => {
      Object.entries(value).map(([_category, cValue]) => {
        Object.entries(cValue).map(([_row, b]) => {
          const { row } = getCloudItemEntry(resourceData, _cloud, _category, _row);
          const selected = b?.units !== undefined && b?.selected;
          if (selected) {
            updateCostSummaryDetails({
              totalcost: service === 'maas' ? (totalUnits * 12) : Math.round(totalUnits + totalUnits * .2 + supportCost),
              resourceCost: (service === 'maas') ? resourceCost * 12: resourceCost,
              projectManagment: (service !== 'maas') && Math.round(totalCost - resourceCost),
              supportCost: (service !== 'maas') && supportCost,
            });
          } else {
            return null;
  
          }
        });
      });
    });
  }, [selectedResources, supportCost]);

  return <div ref={containerRef} className={`mp-summary-container ${success ? 'mp-hidden' : ''}`}>

    <h1 className='mp-summary-title'>Summary</h1>
    {['draas', 'maas'].includes(service) && <>
      <p className='total-cost-note'>Annual Subscription</p>
    </>
    }
    <hr className='divider'/>
    <h3 className='mp-summary-title'>{service === 'maas' ? 'Environment' : 'Source Environment'}</h3>
    <br/>


    <div className={`mp-details ${inReview ? 'mp-details-review' : ''}`}>
      {Object.entries(selectedResources).map(([_cloud, value], index) => {
        const key = `section-${index}`;
        const isCollapsed = inReview ? false : collapsed.includes(key);
        const { cloud } = getCloudItemEntry(resourceData, _cloud);

        return <div key={key} className='mp-section'>
          <span className='mp-title' onClick={() => {
            if (isCollapsed) {
              dispatch(setCollapsed(collapsed.filter(i => i !== key)));
            } else {
              dispatch(setCollapsed([
                ...collapsed,
                key,
              ]));
            }
          }}>
            {isCollapsed ?
              <span style={{ verticalAlign: 'bottom' }}>+</span> :
              <span style={{ verticalAlign: 'bottom' }}>-</span>}&nbsp;{cloud.name
            }
          </span>
          <div className={`mp-section-contents ${isCollapsed ? 'hidden' : ''}`}>
            {Object.entries(value).map(([_category, cValue]) => {
              const { category } = getCloudItemEntry(resourceData, _cloud, _category);
              return <>
                {Object.values(cValue).some(v => v.selected) && <span className='mp-value'>&nbsp;&nbsp;{category.name}<br /></span>}
                {Object.entries(cValue).map(([_row, b]) => {
                  const { row } = getCloudItemEntry(resourceData, _cloud, _category, _row);
                  const selected = b?.units !== undefined && b?.selected;
                  if (selected) {

                    totalUnits += (row?.price ?? 1) *  b.units/(row?.units?.perUnit ?? 1);
                    totalcost= (service === 'maas' ? totalUnits : totalUnits + totalUnits * .2 + supportCost);
                    resourceCost += Math.round((row?.price ?? 1) * b.units/(row?.units?.perUnit ?? 1));
                    totalCost = (service === 'maas' ? totalUnits : totalUnits + totalUnits * 0.2 + supportCost);
                    TotalWancloudsUnit=(unitConversion(service === 'maas' ? totalUnits : totalUnits + totalUnits * .2 + supportCost)/500);
                    finalProgramManagment = (unitConversion(service === 'maas' ? totalUnits : totalUnits + totalUnits * .2) * .2);
                    finalResourceCost = (unitConversion(service === 'maas' ? totalUnits : totalUnits + totalUnits * .2) * .8);
                    programManagment= totalCost - resourceCost-supportCost;
                    // Verify at least 1 resource is selected and isn't 0 units
                    if (b.units !== 0) {
                      noneSelected = false;
                    }

                    return <span className='mp-value'>
                      &nbsp;&nbsp;&nbsp;&nbsp;• {row.resources}: {selected ? b.units : null}<br />
                    </span>;
                  } else {
                    return null;

                  }
                })}

              </>;

            })}
          </div>
        </div>;

      })}

    </div>


    <hr className='divider' />
    <div className='mp-total'>
      <div>
        {
          service !== 'maas' && <><span style={{
            display: 'block',
            fontSize: '16px',
          }}> <span>{service === 'draas' ? 'DR Subscription' : 'Migration Cost'}</span>
            <span className='mp-total-value'>${Math.round(resourceCost)}</span>
          </span>
          <br/>
          </>
        }
        
        {
          service !== 'maas' && <><span style={{
            display: 'block',
            marginTop: '2px',
            fontSize: '16px',
          }}>
            <span>{service === 'draas' ? 'Onboarding & Setup' : 'Program Management'}</span>

            <span className='mp-total-value'>${Math.round(programManagment)}</span>
          </span>
          <br />
          </>
        }

        {
          service !== 'maas' && <>
            <span style={{
              display: 'block',
            }}>
              <div style={{
                display: 'block',
                marginTop: '2px',
                fontSize: '16px',

              }}>
                <span
                  className='mp-support-title'>{service === 'draas' ? 'Support Included' : 'Post Migration Support'}</span>
                {service !== 'draas' && <>
                  <span className='mp-total-value'>${supportCost}</span>
                  <p></p>
                  {!offlineInvoice && supportDisplay({support, setSupport: (value) => dispatch(setSupport(value))})}
                </>
                }
              </div>
              {/* <span className='mp-total-title'>Subtotal</span>

          <span className='mp-total-value'>{subtotal+supportCost}</span> */}
            </span>
            <br />
            <br /></>
        }
        {/*TODO: need to verify with team then we can enable this if its done from backend side*/}
        {service === 'maas' && <div className='display-block margin-bottom-30px'>
          <span className='custom-heading'>Select Payment Option</span>
          <br/>
          <Radio.Group onChange={onPaymentOptionChange} value={paymentOption} className="custom-radio">
            <Radio value="yearly">Yearly</Radio>
            <Radio value="monthly">Monthly</Radio>
          </Radio.Group>
        </div>}
        {(service === 'maas' ) && <div className='display-block margin-bottom-30px'>
          <span className='custom-heading-payment-option'>{paymentOption === 'yearly' ? 'One-time upfront' : 'Montly Price'}</span>
          <span className='custom-heading-payment-option mp-total-value'>${paymentOption === 'yearly' ? 12 * Math.round(totalcost) : Math.round(totalcost)}</span>
        </div>}
        {
          service === 'maas' &&
          <span>
            <p className='note'>
              Wanclouds Managed Service is available on a yearly subscription.
              <br/>
              You have the option to make monthly payments or to choose a one-time payment for the entire year.
            </p>
          </span>
        }
        <span style={{
          display: 'block',
          fontSize: '16px',
        }}>Total Price
          <span
            className='mp-total-value'>${service === 'maas' ? (12 * Math.round(totalcost)) : Math.round(totalcost)}</span>
          <br/>
          <br/>
        </span>
        <br/>
        {step >= 2 &&
            <>
              <div>

                {/* <Button
              onMouseEnter={onSignQuoteBtnMouseEnter}
              onMouseLeave={onSignQuoteBtnMouseLeave}
              type='dashed'
              ghost
              onClick={() => setSignQuoteModalVisibility(true)}
              icon={isSignatureImgUrlGenerated && <CheckCircleOutlined />}
            >
              {(isSignatureImgUrlGenerated && signQuoteBtnHoverState) 
                ? 'Edit Signature'
                : isSignatureImgUrlGenerated ? 'Quote Signed' : 'Sign Qoute'
              }
            </Button> */}

                <Modal
                  title="Sign Quote"
                  open={signQuoteModalVisibility}
                  onCancel={() => setSignQuoteModalVisibility(false)}
                  closable
                  footer={
                    <>
                      <Button onClick={() => {
                        setSignQuoteModalVisibility(false);
                        clearSignature();
                      }}
                      >Cancel</Button>
                      <Button type='dashed' onClick={clearSignature}>Clear</Button>
                      <Tooltip className='ml-10px' title={isSignatureCanvasEmpty && 'Please draw your signature'}>
                        <Button type='primary' onClick={acceptSignature} disabled={isSignatureCanvasEmpty}>OK</Button>
                      </Tooltip>
                    </>
                  }
                >
                  <Card bodyStyle={{ padding: 0, width: '100%' }}>
                    <SignatureCanvas
                      ref={(ref) => (signatureRef.current = ref)}
                      penColor="black"
                      canvasProps={{ id: 'signatureCanvas' , width: 500, height: 300 }}
                      onBegin={() => setIsSignatureCanvasEmpty(false)}
                    />
                  </Card>
                </Modal>
              </div>
              <br/>

              {!offlineInvoice && (
                <ReactToPrint
                  documentTitle="invoice"
                  content={() => ref?.current}
                  trigger={() => (
                    <Button
                      ghost
                    >
                      <span className='color-white'>Download Quote</span>
                    </Button>
                  )}
                />
              )}

            </>
        }
      </div>
      <div style={{marginTop:'40px'}}>
        {step > 0 && (
          <Checkbox
            id='terms'
            className='mp-checkbox'
            checked={agreed}
            onChange={({ target: { checked } }) => dispatch(setAgreed(checked))}
          >
            <span style={{ color: 'white' }}>
                I have read and agree to the Wanclouds  <a href='https://wanclouds.net/terms' target='_blank' rel='noopener noreferrer'>Terms  </a>& <a href='https://wanclouds.net/privacy' target='_blank' rel='noopener noreferrer'> Privacy Policy</a>

            </span>
          </Checkbox>
        )}
        <br />
      </div>
    </div>
    <div className='mp-buttons'>
      {(!offlineInvoice || (offlineInvoice && step === 3)) && (
        <Button
          size="large"
          disabled={step === 0 || submitting || (offlineInvoice && step === 2)}
          onClick={() => {
            if (inReview) {
              dispatch(setAgreed(false));
            }
            dispatch(setStep(step - 1));
          }}
        >
          Back
        </Button>
      )}
      <Button
        size="large"
        type="primary"
        disabled={
          ((step === 1 && noneSelected) || (step === 1 && !agreed)) ||
          // step ===2 && !isInvoiceUrlGenerated ||
          (inSignQuote && !agreed) ||
          (inReview && !agreed) ||
          (step === 0 && missingData) ||
          submitting
        }

        onClick={async () => {
          if (validEmail(email)) {
            if (!emailSent) {
              setEmailSent(true); // Set the state to indicate that the email has been sent
              sendEmail(); // Call the sendEmail function
            }
          }
          if (inSignQuote) {
            setSignQuoteModalVisibility(true);
          } else if (inReview) {
            try {
              dispatch(setSubmitting(true));
              submitOrder();
              dispatch(setStep(step + 1));
            } catch (error) {
              alert('An error occured while submitting billing/plan.');
              dispatch(setSubmitting(false));
            }
          } else {
            if (step === 0 && !validEmail(email)) {
              dispatch(setEmailError(true));
            } else {
              dispatch(setStep(step + 1));
            }
          }

        }}
      >
        {(['Next', 'Review Quote', 'Sign Quote', 'Accept Quote'])[step]}
      </Button>
    </div>



  </div>;
});

export default Summary;
