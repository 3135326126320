import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segmented } from 'antd';

import SelectResourcesTableContainer from './SelectResourcesTableContainer';

import { setConfig } from './reducer';
import './style.scss';

const SelectResources = () => {
  const dispatch = useDispatch();
  const { step } = useSelector(state => state.workflow);
  const { config, resourceData } = useSelector(state => state.resources);
  const { source, service } = useSelector(state => state.billing);
  const currentTabs = service === 'draas' ? ['draas'] : source;

  useEffect(() => {
    dispatch(setConfig(service === 'draas' ? 'draas' : source[0]));
  }, []);

  return (
    <div className={'selectResources'}>
      {service !== 'maas' && <><span className='details-title'>Source Environment</span>
        {service === 'draas' ? (
          <p  className='details-subtitle'
            style={{
              marginBottom: '20px',
            }}>
          Ensure your business resilience with our comprehensive disaster recovery as a service solution, available on annual subscription basis.
          For further queries, reach out to our sales team to discuss your requirements: <a href='mailto:sales@wanclouds.net'>sales@wanclouds.net</a></p>
        ) : (
          <p
            className='details-subtitle'
            style={{
              marginBottom: '20px',
            }}
          >
          Select the resources you want to migrate. If you have additional queries, please contact us at <a href='mailto:sales@wanclouds.net'>sales@wanclouds.net</a>
          </p>
        )}</>}
      <Segmented
        block
        options={currentTabs.map((configType) => {
          const resource = resourceData.find(resource => resource.id === configType);
          return {
            label: resource.name,
            value: configType,
          };
        })}
        value={config}
        onChange={(value) => dispatch(setConfig(value))}
      />
      <SelectResourcesTableContainer />
    </div>
  );
};

export default SelectResources;
