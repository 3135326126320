import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'antd';

import { getCloudItemEntry } from '../../utils';
import { setInvoiceUrl, resetInvoiceUrl } from './reducer';
import CryptoJS from 'crypto-js';



export const supportValues = [{
  id: 'Basic',
  text: '1 month',
  value: 5000,
}, {
  id: 'Advanced',
  text: '3 months',
  value: 20000,
}, {
  id: 'Premium',
  text: '1 year',
  value: 40000,
}];

export const formatMoney = (val) => {
  return (val)?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const supportDisplay = ({ support, setSupport }) => {
  return supportValues.map(entry => (
    <>
      <Checkbox
        key={entry.id}
        id={entry.id}
        className='mp-support-checkbox'
        checked={support?.checked && entry.id === support?.id}
        onChange={({ target: { checked } }) => {
          if (checked) {
            setSupport({ checked, id: entry.id });
          } else {
            setSupport(null);
          }
        }}
      >
        <span style={{ color: 'white' }}>
          {entry.id}: {entry.text}
        </span>
      </Checkbox>
      <br />
    </>
  ));
};

const sleep = ms => new Promise((resolve) => setTimeout(resolve, ms));

export const checkIfInvoiceReady = async (invoice) => {
  let ready = false;

  return new Promise((resolve) => {
    (async () => {
      do {
        const response = await fetch(invoice);
        if (response.status === 200) {
          ready = true;
        } else {
          await sleep(1000);
        }
      } while (!ready);
      resolve();
    })();
  });
};

export const validEmail = (email) => {
  // eslint-disable-next-line no-control-regex
  var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return re.test(email);
};

export const getSummaryData = (perUnitData, resourceData) => Object.entries(perUnitData).map(([_cloud, value]) => {
  const cloudObj = {};
  const cloudItemEntry = getCloudItemEntry(resourceData, _cloud);
  cloudObj.cloudName = cloudItemEntry.cloud.name;
  cloudObj.categories = Object.entries(value).map(([_category, cValue]) => {
    const categoryObj = {};
    const categoryItemEntry = getCloudItemEntry(resourceData, _cloud, _category);
    categoryObj.categoryName = categoryItemEntry.category.name;
    categoryObj.resources = Object.entries(cValue).map(([_row, b]) => {
      const resourceObj = {};
      const resourceItemEntry = getCloudItemEntry(resourceData, _cloud, _category, _row);
      resourceObj.resourceName = resourceItemEntry.row.resources;
      resourceObj.units = b?.units !== undefined && b?.selected ? b.units : null;
      return resourceObj;
    });
    return categoryObj;
  });
  return cloudObj;
});

const addDollarToCost = (cost) => ['string', 'number'].includes(typeof cost) && `$${cost}`;


export const getSummarizedCost = ({ service, paymentOption, resourceCost, supportCost, projectManagment, totalcost }) => {
  return ({
    'Service': service === 'draas' ? 'Disaster Recovery as a Service' : service === 'maas' ? 'Multi-Cloud Manage Service' : 'Migration as a Service',
    'Resource Cost': addDollarToCost((service === 'maas' && paymentOption === 'monthly') ? resourceCost/12 : resourceCost),
    ...((service === 'maas' && paymentOption === 'monthly') && { 'Monthly Cost': addDollarToCost(resourceCost/12) }),
    ...(projectManagment && { [`${service === 'draas' ? 'Cloud Setup' : 'Program Management'}`]: addDollarToCost(projectManagment) }),
    ...((supportCost && service !== 'draas') && { 'Post Migration Support': addDollarToCost(supportCost) }),
    'Total Cost': addDollarToCost(totalcost),
  });
}


export const useProcessInvoice = ({
  step, source, destination, email, name, support, perUnitData,
}) => {
  const dispatch = useDispatch();

  const processInvoice = async () => {
    try {
      if (step === 2) {
        const result = await (await fetch(`${process.env.REACT_APP_BILLING_API}/v1/order`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            name,
            email,
            destination,
            source,
            support,
            resource_list: perUnitData,
          }),
        })).json();
    
        const invoice = await result.invoice;
        dispatch(setInvoiceUrl(invoice));
      }
      return null;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted && step === 2) {
      processInvoice();
    } else {
      dispatch(resetInvoiceUrl());
    }

    return () => {
      isMounted = false;
      dispatch(resetInvoiceUrl());
    };
  }, [step]);
  return;
};

export const convertSignatureToImage = (canvas, updateSignatureImgUrl) => {
  canvas.toBlob((blob) => {
    const url = URL.createObjectURL(blob);
    updateSignatureImgUrl(url);
  });
};


/**
 * 
 * @param {string} data 
 * @param {function} updateQuoteOfflineURL 
 * @returns 
 */
export const generateQuoteOfflineURL = (data, updateQuoteOfflineURL) => {
  // remove the dummy secret key here, use from environment variable
  const secretkey = process.env.SECRET_KEY || 'asdashdbjhb2hj2bjb3bh23bb2jb3jhb234';
  const websiteUrl = process.env.WEBSITE || 'https://billing.wanclouds.net/maas';
  const id = CryptoJS.AES.encrypt(data, secretkey).toString();
  updateQuoteOfflineURL(`${websiteUrl}/quote/${id}`);
};

export const extractDataFromQuoteOfflineURL = (encryptedData) => {
  // remove the dummy secret key here, use from environment variable
  const secretkey= process.env.SECRET_KEY || 'asdashdbjhb2hj2bjb3bh23bb2jb3jhb234';
  return CryptoJS.AES.decrypt(encryptedData, secretkey).toString(CryptoJS.enc.Utf8);
};

