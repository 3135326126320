import React, { useRef } from 'react';
import logo from '../logo.svg';
import './invoice.scss';

import { Divider, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

const { Text } = Typography;

const InvoiceHeader = ({ currentDate, paymentDueDate }) => {
  const { invoiceIssued, invoiceDue } = useSelector(state => state.summary);

  return (
    <div id="invoice-top">
      <div className="logo"><img src={logo} alt="logo" /></div>
      <div className="flex justify-space-between flex-gap-x-10px">
        <div>
          <div className="flex flex-col">
            <div className="info">
              <h2>Wanclouds Inc.</h2>
              <a href="mailto:sales@wanclouds.net">sales@wanclouds.net</a>
              <br/>
              <br/>
              <p>2445 Augustine Drive, Suite 401, Santa Clara, CA 95054</p>
            </div>
          </div>
        </div>
        <div className="title">
          <h1>Quote</h1>
          <p>
            Issued: {invoiceIssued || currentDate}<br/>
            Payment Due: {invoiceDue || paymentDueDate}
          </p>
        </div>
      </div>
    </div>
  );
};
const InvoiceBody = ({ invoice, customerDetails, cost, currentDate, signatureImgUrl, isSignatureImgUrlGenerated }) => {
  const currentDateInUTC = useRef(null);

  const renderResources = () => (
    Object.keys(invoice)?.map(item => (
      invoice[item]?.categories?.map(category =>
        category?.resources
          ?.filter((resource) => resource?.units)
          ?.map(resource => (
              <tr key={resource?.resourceName} className="service">
                <td className="tableitem">
                  <p className="itemtext">{resource?.resourceName}</p>
                </td>
                <td className="tableitem">
                  <p className="itemtext">{invoice[item].cloudName}</p>
                </td>
                <td className="tableitem">
                  <p className="itemtext">{resource?.units}</p>
                </td>
              </tr>
          ))
      )
    ))
  );
  const getCurrentDateTimeInUTC = () => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC'
    };
    
    const now = new Date();
    return `${new Intl.DateTimeFormat('en-US', options).format(now)} UTC`;
  };

  if (isSignatureImgUrlGenerated) {
    currentDateInUTC.current = getCurrentDateTimeInUTC();
  }
  return (
    
    <div id="invoice-mid">
      <div className='customer-info'>
        <div className="info">
          <h2>Customer Contact Info:</h2>
          <p>
            Customer Name: {customerDetails?.fullName}<br/>
            Customer Email: {customerDetails?.email}<br/>
            Company Name: {customerDetails?.companyName}<br/>
            Billing Address: {customerDetails?.billingAddress}<br/>
            {customerDetails?.requesterEmail
              ? <>
                  Requester Email: {customerDetails?.requesterEmail}<br/>
              </> : null}
          </p>
        </div>
        <div id="project">
          <h2>Description</h2>
          <p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            This Order Form (the "Order Form") is effective as of {currentDate} by both Customer (as set forth below) and Wanclouds Inc. ("Wanclouds") (the "Effective Date"). This Order Form is incorporated into and part of the Service Agreement (the “Agreement”) either attached hereto, or if no Agreement is attached, the Agreement located at www.wanclouds.net/terms. The terms not defined in this Order Form are defined in the Agreement. This Order Form may be executed in multiple counterparts, each of which shall be an original, but all of which shall constitute one instrument.
          </p>
        </div>
      </div>
      <Divider />
      <div id="table">
        <div className="resources-table">
          <table>
            <tr className="tabletitle">
              <td className="Hours">
                <h2>Resource</h2>
              </td>
              <td className="Hours">
                <h2>Cloud</h2>
              </td>
              <td className="subtotal">
                <h2>Units</h2>
              </td>
            </tr>
            {renderResources()}
          </table>
        </div>
        <div className="mt-20px flex justify-space-between">
          <div className="full-width"/>
          <div className="full-width">
            <table>
              {Object.entries(cost)?.map(([key, value]) => (
                <tr key={key} className="costsummary" id={key === 'Total Cost' && 'total-cost'}>
                  <td className="key">
                    <p>{key}</p>
                  </td>
                  <td className="value">
                    <p>{value}</p>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        {isSignatureImgUrlGenerated && (
          <div className="signature-container">
            <div>
              <Text strong>Signed by:</Text>
              <div style={{ height: '65px', maxHeight: '65px'}}>
                <img id='signature' src={signatureImgUrl} />
              </div>
            </div>
            <div className='mt-5px flex flex-gap-x-10px'>
              <Text strong>Name:</Text>
              <Text>{customerDetails?.fullName}</Text>
            </div>
            <div className="flex flex-gap-x-10px">
              <Text strong>Billing Address:</Text>
              <Text>{customerDetails?.billingAddress}</Text>
            </div>
            <div className="flex flex-gap-x-10px">
              <Text strong>Date:</Text>
              <Text>{currentDateInUTC.current}</Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InvoiceFooter = ({ offlineInvoice, step, order, service, invoiceUrl, invoiceOfflineUrl, isInvoiceOfflineUrlGenerated }) => {

  return (
    <div id="invoice-bot">
      <div id="legalcopy">
        <p className="legal">
          <strong>Subscription & Payments:</strong><br/>
          {service !== 'draas' ? <>Payment Schedule: Annual Net Payment <br/></> : null}
          Net Payment Term: Due on Receipt<br/>
          {/*Service Term: Annual (12 Months)<br/>*/}
          Service Term: {service === 'draas' ? 'Annual(12 Months)' : 'One Time Payment'} <br/>
          Auto Renewal: Auto Renewal invoices are sent unless Customer provides Wanclouds with written notice of intent to terminate forty five (45) days prior to the end of the Term. For more information, reach out at sales@wanclouds.net
        </p>
      </div>
      {/*NOTE: Will be needed in later iterations*/}
      {!offlineInvoice && step === 2 && isInvoiceOfflineUrlGenerated && (
        <div className="payment-button">
          <a
            style={{
              padding: '10px 20px',
              backgroundColor:'#1D4ED8',
              color:'white',
              border:'none',
            }}
            href={invoiceOfflineUrl}
            target="_blank"
            alt="sign quote link" rel="noreferrer"
          >
            Sign Quote
          </a>
        </div>
      )}

    </div>
  );
};

const Invoice = ({ offlineInvoice, step, order, service, invoice, cost }) => {
  
  const { email, name, Last, companyName, billingAddress, requesterEmail } = useSelector(state => state.billing);

  const { 
    invoiceUrl,
    isInvoiceUrlGenerated,
    signatureImgUrl,
    isSignatureImgUrlGenerated,
    invoiceOfflineUrl,
    isInvoiceOfflineUrlGenerated,
  } = useSelector(state => state.summary);


  const getPaymentDueDate = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    let nextMonth = currentDate.getMonth() + 1;
    let nextYear = currentDate.getFullYear();
    if (nextMonth > 11) {
      nextMonth = 0;
      nextYear++;
    }
    const daysInNextMonth = new Date(nextYear, nextMonth + 1, 0).getDate();
    let paymentDueDate;
    if (currentDay <= daysInNextMonth) {
      paymentDueDate = new Date(nextYear, nextMonth, currentDay);
    } else {
      paymentDueDate = new Date(nextYear, nextMonth, daysInNextMonth);
    }

    return paymentDueDate;
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };
  const currentDate = formatDate(new Date());
  const paymentDueDate = formatDate(getPaymentDueDate());
  const resourcesLength = Object.keys(invoice)?.map(key => invoice[key]?.categories)?.length;
  const fullName = name + ' ' + Last;

  return (
    <div id="invoice-container">
      <div className='invoice'>
        <InvoiceHeader
          currentDate={currentDate}
          paymentDueDate={paymentDueDate}
        />
        <Divider className="zero-margin" />
        <InvoiceBody
          service={service}
          invoice={invoice}
          customerDetails={{ email, fullName, companyName, billingAddress, requesterEmail }}
          cost={cost}
          resourcesLength={resourcesLength}
          currentDate={currentDate}
          signatureImgUrl={signatureImgUrl}
          isSignatureImgUrlGenerated={isSignatureImgUrlGenerated}
        />
        <Divider className="zero-margin" />

        <InvoiceFooter
          offlineInvoice={offlineInvoice}
          step={step}
          order={order}
          service={service}
          invoiceUrl={invoiceUrl}
          invoiceOfflineUrl={invoiceOfflineUrl}
          isInvoiceOfflineUrlGenerated={isInvoiceOfflineUrlGenerated}
        />

      </div>
    </div>
  );
};

  
export default Invoice;
