import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import workflow from './components/Workflow/reducer';
import billing from './components/Billing/reducer';
import resources from './components/SelectResources/reducer';
import summary from './components/Summary/reducer';

export const rootReducer = combineReducers({
  workflow,
  billing,
  resources,
  summary,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});

export default store;
