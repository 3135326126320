import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, InputNumber, Table } from 'antd';
import { set, get, cloneDeep } from 'lodash';

import TableHeader from './TableHeader';

import { setPerUnitData, setSelectedResources } from './reducer';
const { TextArea } = Input;

const SelectResourcesTable = ({ headers, categories }) => {
  const dispatch = useDispatch();
  const { selectedResources, perUnitData, config: type } = useSelector(state => state.resources);

  const setData = (path, value, step = 1) => {
    const newData = set(cloneDeep(selectedResources), path.replace(/:/g, '.'), value);
    const dataPerUnit = set(cloneDeep(perUnitData), path.replace(/:/g, '.'), typeof value === 'number' ? value/step : value);
    dispatch(setSelectedResources(newData));
    dispatch(setPerUnitData(dataPerUnit));
  };
  const getData = (path) => get(selectedResources, path.replace(/:/g, '.'));

  const columns = headers.map((entry) => ({
    title: entry.header,
    dataIndex: entry.id,
  }));

  const rows = categories.reduce((acc, category) => {
    acc.push(
      category.rows.reduce((acc, { id, resources, category: rowCategory, units, description, notes,unitpricing }) => {
        const key = `${type}:${category.id}:${id}`;
        acc.push({
          id: key,
          resources,
          category: rowCategory,
          unitpricing,
          units,
          description,
          notes,
          isSelected: getData(`${key}:selected`),
          isExpanded: getData(`${key}:expanded`),
        });
        return acc;
      }, [])
    );
    return acc;
  }, []).flat();

  return <>
    <Table
      tableLayout="fixed"
      pagination={false}
      rowSelection={{
        type: 'checkbox',
        hideSelectAll: true,
      }}
      expandable={{
        expandedRowRender: true,
      }}
      columns={columns}
    />
    {categories.map(category => {
      let included = [];
      let dataSource = [];
      let selectedRowKeys = [];
      let expandedRowKeys = [];
      category.rows.map((row) => {
        const key = `${type}:${category.id}:${row.id}`;
        const rowData = rows.find((row) => row.id === key);
        if (rowData.isSelected) selectedRowKeys.push(key);
        if (rowData.isExpanded) expandedRowKeys.push(key);
        if (rowData.units !== 'Included') {
          dataSource.push({
            key,
            resources: rowData.resources,
            category: rowData.category,
            unitpricing: rowData.unitpricing,
            units:
              <div style={{
                marginRight: '64px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '150px',
              }}>
                <InputNumber
                  min={rowData.units.min}
                  max={rowData.units.max}
                  value={getData(`${key}:units`) ?? rowData.units.defaultValue}
                  onChange={(value) => setData(`${key}:units`, +value, rowData.units.perUnit)}
                  size='sm'
                  step={rowData.units?.unit === 'GB' ? rowData.units.perUnit : 1}
                />
                {rowData.units.unit && <span style={{ marginLeft: '8px' }}>{rowData.units.unit}</span>}
              </div>,
            description: rowData.description,
            notes: rowData.notes,
          });
        } else {
          included.push(rowData);
        }
        return null;
      });

      if (included.length) {
        const key = `${type}:${category.id}:_included_`;
        const rowData = getData(`${type}:${category.id}:_included_`);
        if (rowData?.expanded) expandedRowKeys.push(key);

        dataSource.push({
          key,
          resources: 'Included',
          category: '',
          units: '',
          description: <>
            {included.map((item) => <>- {item.resources}<br /></>)}
          </>
        });
      }

      return <>
        <TableHeader text={category.name} />
        <Table
          showHeader={false}
          pagination={false}
          tableLayout="fixed"
          rowSelection={{
            selectedRowKeys,
            type: 'checkbox',
            getCheckboxProps: (record) => ({
              style: record.resources === 'Included' ? { display: 'none' } : {},
              name: record.name,
            }),
            onSelect: (record, selected) => {
              const rowData = rows.find((row) => row.id === record.key);
              setData(record.key, {
                ...getData(record.key),
                ...(getData(`${record.key}:units`) === undefined && {
                  units: rowData.units.defaultValue,
                }),
                selected,
              });
            },
          }}
          expandable={{
            expandedRowKeys,
            expandedRowRender: record => {
              return <div
                style={{
                  display: 'flex',
                }}
              >
                <p style={{
                  margin: 0,
                  marginLeft: 100,
                  marginRight: 50,
                }}>
                  {record.description}
                </p>
                {record.notes && <Form.Item
                  label='Notes'
                >
                  <TextArea
                    showCount
                    maxLength={200}
                    placeholder='Additional details'
                    style={{
                      height: '150px',
                      minWidth: '288px',
                    }}
                    value={getData(`${record.key}:notes`)}
                    onChange={(event) => setData(`${record.key}:notes`, event.target.value)}
                  />
                </Form.Item>}
              </div>;
            },
            onExpand: (expanded, record) => {
              setData(`${record.key}:expanded`, expanded);
            },
          }}
          columns={columns}
          dataSource={dataSource}
        />
      </>;
    })}
  </>;
};

export default SelectResourcesTable;
