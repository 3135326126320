import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  step: 0,
  order: null,
  status: null,
  showRedirect: false,
};

const slice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setShowRedirect(state, action) {
      state.showRedirect = action.payload;
    },
  },
});

export const {
  setStep, setOrder, setStatus, setShowRedirect,
} = slice.actions;
export default slice.reducer;
