import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  collapsed: [],
  agreed: false,
  submitting: false,
  support: null,
  isInvoiceUrlGenerated: false,
  invoiceUrl: null,
  signatureImgUrl: null,
  isSignatureImgUrlGenerated: null,
  invoiceOfflineUrl: '',
  isInvoiceOfflineUrlGenerated: false,
  invoiceIssued: '',
  invoiceDue: '',

};

const mainSlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    setAgreed(state, action) {
      state.agreed = action.payload;
    },
    setSubmitting(state, action) {
      state.submitting = action.payload;
    },
    setSupport(state, action) {
      state.support = action.payload;
    },
    setInvoiceUrl(state, action) {
      state.invoiceUrl = action.payload;
      state.isInvoiceUrlGenerated = true;
    },
    resetInvoiceUrl(state) {
      state.invoiceUrl = initialState.invoiceUrl;
      state.isInvoiceUrlGenerated = initialState.isInvoiceUrlGenerated;
    },
    setSignatureImgUrl(state, action) {
      state.signatureImgUrl = action.payload;
      state.isSignatureImgUrlGenerated = true;
    },
    resetSignatureImgUrl(state) {
      state.signatureImgUrl = initialState.signatureImgUrl;
      state.isSignatureImgUrlGenerated = initialState.isSignatureImgUrlGenerated;
    },

    setInvoiceOfflineUrl(state, action) {
      state.invoiceOfflineUrl = action.payload;
      state.isInvoiceOfflineUrlGenerated = true;
    },
    setInvoiceIssued(state, action) {
      state.invoiceIssued = action.payload;
    },
    setInvoiceDue(state, action) {
      state.invoiceDue = action.payload;
    },
    resetQuoteOfflineUrl(state) {
      state.invoiceOfflineUrl = initialState.invoiceOfflineUrl;
      state.isInvoiceOfflineUrlGenerated = initialState.isInvoiceOfflineUrlGenerated;
    },

  },
});

export const {

  setCollapsed,
  setAgreed,
  setSubmitting,
  setSupport,
  setInvoiceUrl,
  resetInvoiceUrl,
  setSignatureImgUrl,
  resetSignatureImgUrl,
  setInvoiceOfflineUrl,
  resetQuoteOfflineUrl,
  setInvoiceIssued,
  setInvoiceDue,

} = mainSlice.actions;
export default mainSlice.reducer;
