export const sourceEnvironments = [
  { id: 'aws', category: 'source', text: 'Amazon Web Services' },
  { id: 'gcp', category: 'source', text: 'Google Cloud Platform' },
  { id: 'ibmCloud', category: 'source', text: 'IBM Cloud' },
  { id: 'ibmCloudClassic', category: 'source', text: 'IBM Cloud Classic' },
  { id: 'azure', category: 'source', text: 'Microsoft Azure' },
  { id: 'onPrem', category: 'source', text: 'On Premise' },
  { id: 'draas', category: 'Service', text: 'Disaster Recovery as a Service' },
];

export const destinationEnvironments = [
  { id: 'aws', text: 'Amazon Web Services' },
  { id: 'gcp', text: 'Google Cloud Platform' },
  { id: 'ibmCloud', text: 'IBM Cloud' },
  { id: 'azure', text: 'Microsoft Azure' },
  { id: 'other', text: 'Other' },
];

export const ibmAccountIdText = `To find your IBM Account ID, go to the Account settings page in the console to view your account ID and type.
The account ID is a 32 character, unique account identifier.
The IBM Cloud console menu bar lists all of the accounts that are affiliated with your IBMid, including the accounts that you own.
The account selector displays the account name and account number.`;

export const services = [
  { id: 'draas', text: 'Disaster Recovery as a Service', value: 'draas' },
  { id: 'migration', text: 'Migration as a Service', value: 'migration' },
  { id: 'maas', text: 'Multi-Cloud Manage Service', value: 'maas' },
];
